(function ($, root, undefined) {

    $(function () {

        'use strict';
        
        // Global vars
        var stickyHide = 0;
        
        initNavLang();
        initVideos();
        var lazyLoadInstance = new LazyLoad({
        // Your custom settings go here
      });
        /************
          FUNCTIONS
        *************/
        
        function initNavLang() {
            $('header .languages>.current').click(function() {
                $('#search-desktop').toggleClass('is-lang');
            });
            
            var search = $('.search-trigger');
            
            search.on('click', function() {
                $('header nav .languages').addClass('is-hidden');
            });
        }
        
        //Formulaire de recherche - guide des aides
        var form = $('.filters-aides');
        if ($('body').hasClass('page-template-aides')){
            $('.aide_programmation select').change(function(){
                var returnProgrammation = $(this).val();
                form.find('input[name="programmation"]').attr('value',returnProgrammation);
                form.trigger('submit');
            });
            $('.aide_fonds select').change(function(){
                var returnFonds = $(this).val();
                form.find('input[name="fonds"]').attr('value',returnFonds);
                form.trigger('submit');
            });
        }

        function initVideos() {
            var videos = $('.bloc-videos .video');

            var video = videos.find('.play');
            $(video).each(function() {
                $(this).magnificPopup({
                    type: 'iframe',
                    items: {
                        src: $(this).attr('data-url')
                    },
                    mainClass: 'mfp-fade',
                    removalDelay: 160, 
                    preloader: false,
                    fixedContentPos: true,
                    overflowY: 'hidden'
                });
            }); 
        }

        // Resize
        
        // Throttle
        function throttle(func, wait, leading, trailing, context) {
            var ctx, args, result;
            var timeout = null;
            var previous = 0;
            var later = function() {
                previous = new Date;
                timeout = null;
                result = func.apply(ctx, args);
            };
            return function() {
                var now = new Date;
                if (!previous && !leading) previous = now;
                var remaining = wait - (now - previous);
                ctx = context || this;
                args = arguments;
                if (remaining <= 0) {
                    clearTimeout(timeout);
                    timeout = null;
                    previous = now;
                    result = func.apply(ctx, args);
                } else if (!timeout && trailing) {
                    timeout = setTimeout(later, remaining);
                }
                return result;
            };
        };
        

    });
})(jQuery, this);